/* eslint-disable */
export const voidFunction = () => {};
/* eslint-enable */

export function simpleThrottle<T extends unknown[]>(
  func: (...args: T) => void,
  interval = 500
) {
  let lastTime = 0;
  return (...args: T) => {
    const now = Date.now();
    if (now - lastTime >= interval) {
      func(...args);
      lastTime = now;
    }
  };
}

export function simpleDebounce<T extends unknown[]>(
  func: (...args: T) => void,
  wait = 500,
  immediate = false
) {
  let timeout;
  return function (...args: T) {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(this, args);
    }, wait);
    if (immediate && !timeout) func.apply(this, args);
  };
}

//处理新闻url标题
export function handleNewsTitle(title: string): string {
  return title.replace(/!|:/g, '').split(' ').join('-').toLowerCase();
}

export const createObject = <T>(originObj?: T): T =>
  Object.assign({}, originObj);

export const omit = <T, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> => {
  const result = createObject(obj);

  keys.forEach((item) => {
    delete result[item];
  });

  return result;
};

/**
 * 数组去重
 */
export function uniqueArray<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export function getCookie(name: string): string {
  if (!document) return '';
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  const arr = document.cookie.match(reg);
  if (arr) {
    return arr[2];
  } else {
    return '';
  }
}
