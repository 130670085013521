import { Fragment, useEffect, useRef } from 'react';
import { ConfigProvider } from 'antd';
import type { AppProps, AppContext } from 'next/app';
import ErrorPage from 'next/error';
import { IntlProvider } from 'react-intl';
import MainLayout from '@/layouts/main';
import SEO from '@/components/common/SEO';
import GoogleAnalytics from '@/components/common/GoogleAnalytics';
import { simpleDebounce, voidFunction } from '@/utils';
import theme from '@/theme/themeConfig';
import '@/styles/global.scss';
import { addGtagEvent } from '@/utils/addGtagEvent';
import { useRouter } from 'next/router';
import { gaEventName } from '@/constants/googleAnalytics';

const zhPathname = ['/upgrade'];

const localeClassNameLabel = {
  'en-US': 'en',
  'zh-CN': 'zh',
};
interface IAppProps {
  locale: string;
  messages: any;
  fetchError: boolean;
}

function getDepthPercent(
  pageHeight: number,
  clientHeight: number,
  scrollTop: number
) {
  if (scrollTop === 0) return 0;
  if (scrollTop + clientHeight >= pageHeight) return 100;
  const scrollPercent = (
    (scrollTop / (pageHeight - clientHeight)) *
    100
  ).toFixed(2);
  return Number(scrollPercent);
}

const MyApp = ({ Component, pageProps }: AppProps<IAppProps>) => {
  const { locale, messages, fetchError } = pageProps;
  const maxDepthPercent = useRef(0);
  const { route, pathname } = useRouter();

  useEffect(() => {
    const handleScroll = simpleDebounce(() => {
      const {
        clientHeight,
        scrollHeight: pageHeight,
        scrollTop,
      } = document.documentElement;
      const depthPercent = getDepthPercent(pageHeight, clientHeight, scrollTop);

      maxDepthPercent.current = Math.max(maxDepthPercent.current, depthPercent);
    }, 500);

    window.addEventListener('scroll', handleScroll);

    return () => {
      addGtagEvent(gaEventName.SCROLL, {
        scroll_depth: maxDepthPercent.current + '%',
      });
      maxDepthPercent.current = 0;
      window.removeEventListener('scroll', handleScroll);
    };
  }, [route]);

  if (fetchError) {
    return <ErrorPage statusCode={404} />;
  }

  const IntlText = ({ children }) => (
    <span className={localeClassNameLabel[locale]}>{children}</span>
  );

  return (
    <Fragment>
      <SEO messages={messages} />
      <GoogleAnalytics />
      <IntlProvider
        locale={locale}
        messages={messages}
        textComponent={IntlText}
        onError={voidFunction}
      >
        <ConfigProvider theme={theme}>
          <MainLayout>
            <Component {...pageProps} />
          </MainLayout>
        </ConfigProvider>
      </IntlProvider>
    </Fragment>
  );
};

MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps = Component.getInitialProps
    ? await Component.getInitialProps(ctx)
    : {};

  const { pathname, locale, defaultLocale, query } = ctx;
  let curLocale = locale || defaultLocale || 'en-US';
  //只支持中文的页面
  if (zhPathname.includes(pathname)) {
    curLocale = 'zh-CN';
  }

  /* eslint-disable */
  let messages = require(`../../public/intl/messages/common/${curLocale}.json`),
    pathMessages;
  /* eslint-enable */
  let fetchError = false;

  if (pathname !== '/_error' && !pathname.startsWith('/news/')) {
    const curPathname = pathname === '/' ? '/home' : pathname;
    /* eslint-disable */
    pathMessages = require(`../../public/intl/messages${curPathname}/${curLocale}.json`);
    /* eslint-enable */

    messages = { ...messages, ...pathMessages };
  } else if (pathname === '/news/[slug]') {
    try {
      pathMessages = require(`../../public/overseacdn/homepage/static/news/${query?.slug}.json`);
    } catch (e) {
      // console.error(e)
      fetchError = true;
    }
    messages = { ...messages, ...pathMessages };
  }

  pageProps = {
    ...pageProps,
    locale: curLocale,
    messages,
    fetchError,
  };

  return { pageProps };
};

export default MyApp;
