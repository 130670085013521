import Head from 'next/head';
import { STATIC_ASSETS_URL } from '@/constants/common';
import { useRouter } from 'next/router';

const csp = `script-src
https://www.googletagmanager.com
https://overseacdn.ydstatic.com
https://shared.youdao.com
'unsafe-eval' 'unsafe-inline' 'self';`;

const SEO = (props: { messages: Record<string, any> }) => {
  const { messages } = props;
  const router = useRouter();
  const pathName =
    router.pathname === '/' ? 'home' : router.pathname.replace(/\//g, '');
  const title = messages[`${pathName}.meta.title`] || 'Youdao Ads';
  const keyword = messages[`${pathName}.meta.keywords`] || 'Youdao Ads';
  const desc = messages[`${pathName}.meta.desc`] || 'Youdao Ads';

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="google-site-verification"
        content="sS0wv12KL0pIGIaE4WbKXb98FaJL9J3Bur4tNXaju0s"
      />
      <meta name="baidu-site-verification" content="codeva-1bf8tMAgu9" />
      <meta
        name="facebook-domain-verification"
        content="g4glrplbs7km4kz5elgb7xfrppenag"
      />
      <meta httpEquiv="Content-Security-Policy" content={csp} />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="keywords" content={keyword} />
      <meta name="description" content={desc} />
      <link
        rel="shortcut icon"
        href={`${STATIC_ASSETS_URL}/overseacdn/homepage/favicon.ico`}
        type="image/ico"
      />
    </Head>
  );
};

export default SEO;
