var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9df5f0239c1785f443786feaf766912312851b0d"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/overseacdn/homepage";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN;
const SENTRY_RELEASE = process.env.npm_package_version;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  enabled: Boolean(SENTRY_DSN),
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    /is not defined$/,
    'Unexpected number',
    "Cannot read properties of undefined (reading 'ALGOLIA_APP_ID')",
    "Cannot read properties of undefined (reading 'STRIPE_API_PUBLIC_KEY')",
    "Cannot read properties of undefined (reading 'userAgent')",
    "Cannot read properties of undefined (reading 'version')",
  ],
  release: SENTRY_RELEASE,
});
