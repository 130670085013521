import dayjs from 'dayjs';
import { GaEventName } from 'src/constants/googleAnalytics';

export const gtagHasLoaded = () => {
  return Boolean(document.getElementById('gtag-init'));
};

//对象转二维数组
export const objectToArray = (obj = {}) => {
  const arr: [string, string | number | null][] = [];
  Object.keys(obj).forEach((key) => {
    arr.push(['ex_' + key, obj[key]]);
  });
  return arr;
};

export const addRlog = ({ event, options }) => {
  if (typeof window !== 'undefined' && window._rlog) {
    //处理pv
    if (event === 'page_view') {
      Object.keys(options).forEach((k) =>
        window._rlog.push(['_addPost', 'ex_' + k, options[k]])
      );
      window._rlog.push(['_trackPageview', 'ido']);
      Object.keys(options).forEach((k) =>
        window._rlog.push(['_removePost', 'ex_' + k])
      );
      return;
    }
    //处理click事件
    if (event === 'click' && options?.click_id) {
      event = options.click_id;
      delete options.click_id;
    }
    window._rlog.push(['_addPost', 'ex_page_path', window.location.pathname]);
    if (options) {
      window._rlog.push([
        '_trackCustom',
        'EVENT',
        [['event_id', event], ...objectToArray(options)],
      ]);
    } else {
      window._rlog.push(['_trackEvent', event]);
    }
    window._rlog.push([
      '_removePost',
      'ex_page_path',
      window.location.pathname,
    ]);
  }
};

export function addGtagEvent(
  event: GaEventName,
  options?: Record<string, string>
) {
  if (!gtagHasLoaded()) return;
  //发送请求
  gtag('event', event, options);
  addRlog({ event, options });
}
