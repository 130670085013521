import { useRouter } from 'next/router';
import { CSSProperties } from 'react';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import Logo from '@/components/common/Logo';
import SocialApp from '@/components/common/SocialApp';
import { bannerList } from './Header';
import Business from './Business';
import styles from './Footer.module.scss';
import { EnLogo, ZhLogo } from '@/components/common/svg/netease';

export type AppList = {
  name: string;
  index?: number;
  url?: string;
};

type FormattedRequire = {
  id: string;
  defaultMessage: string;
  url?: string;
};

const appList: AppList[] = [
  {
    name: 'wechat',
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/youdaoadsofficial/',
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/channel/UCI5KmheNTvuH-qDSvTcdtUw',
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/YoudaoAds',
  },
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/company/youdao-ads',
  },
];

const contactList: FormattedRequire[] = [
  {
    id: 'footer.contact.email',
    defaultMessage: 'Email : YDinfluencers@rd.netease.com',
  },
  {
    id: 'footer.contact.phone',
    defaultMessage: 'Phone Number: 400-6789-163',
  },
  {
    id: 'footer.contact.worktime',
    defaultMessage: 'Working Time : Monday to Friday 09:30-18:30',
  },
];

const policiesList: FormattedRequire[] = [
  {
    id: 'footer.service.agreement',
    defaultMessage: 'Terms of Service',
    url: 'https://www.youdaoads.com/user-terms',
  },
  {
    id: 'footer.privacy.policy',
    defaultMessage: 'Privacy Policy',
    url: 'https://www.youdaoads.com/a/private-policy',
  },
  {
    id: 'footer.minors.privacy.policy',
    defaultMessage: 'Privacy Policy for Minors',
    url: 'https://www.youdaoads.com/private-policy-minors',
  },
];

const notShowBusinessPaths = ['/contact', '/upgrade'];

const Footer: React.FC = () => {
  const { pathname, locale } = useRouter();

  const secStyle: CSSProperties = {};
  if (notShowBusinessPaths.includes(pathname)) {
    secStyle.marginTop = 100;
  }

  const handleGo2 = (url: string) => {
    if (!url) {
      return;
    }
    window.open(`${url}?hl=${locale}`, '_blank');
  };
  return (
    <footer>
      {!notShowBusinessPaths.includes(pathname) && <Business />}
      <div className={styles.footer}>
        <div className={styles.content}>
          <section style={secStyle} className={styles.left}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <div className={styles.logotext}>
              <FormattedMessage
                id="footer.logo.text1"
                defaultMessage="Global leading digital marketing solution provider, fusing technology and brand force to drive business growth"
              />
              <FormattedMessage
                id="footer.logo.text2"
                defaultMessage="Drive growth harnessing the power of branding and technologies"
              />
            </div>
            <div className={styles.applist}>
              {appList.map((item, index) => {
                return (
                  <SocialApp
                    name={item.name}
                    url={item.url}
                    index={index}
                    key={index}
                  />
                );
              })}
            </div>
          </section>

          <section style={secStyle} className={styles.right}>
            <div className={styles.nav}>
              <FormattedMessage
                id="footer.understand.us"
                defaultMessage="Contact us"
              />
              <ul>
                {bannerList.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link href={item.route}>
                        <a>
                          <FormattedMessage
                            id={item.title}
                            defaultMessage={item.defaultMessage}
                          />
                        </a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={styles.contact}>
              <FormattedMessage
                id="footer.contact.us"
                defaultMessage="Contact"
              />
              <ul>
                {contactList.map((item, index) => (
                  <li key={index}>
                    <FormattedMessage
                      id={item.id}
                      defaultMessage={item.defaultMessage}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
        <div className={styles.netease__icon}>
          <ZhLogo />
          <EnLogo />
        </div>
        <div className={styles.policies}>
          <ul>
            {policiesList.map((item, index) => (
              <li key={index} className={index === 1 ? styles.border : ''}>
                <span onClick={handleGo2.bind(null, item.url)}>
                  <FormattedMessage
                    id={item.id}
                    defaultMessage={item.defaultMessage}
                  />
                </span>
              </li>
            ))}
            <li className={styles.record}>
              <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                rel="noreferrer"
              >
                京ICP备10005211号-20
              </a>
            </li>
            <li className={styles.netease__num}>163 188 126</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
